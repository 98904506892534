import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Grid from "~components/common/Grid";
import Divider from "~components/common/Divider";
import Breadcrumb from "~components/layouts/Breadcrumb";
import LogoIcon from "~components/svgs/SvgLogoIcon";
import ProvenExpert from "~components/features/ProvenExpert";

export default function Page({ data, location }) {
	const isSSR = typeof window === "undefined";
	return (
		<Layout
			location={location}
			title="Wer ist Lautenschlager Marketing & Entwicklung?"
			desc="Lautenschlager Marketing & Entwicklung sind Experten für das Frontend und deren Technik einer Website."
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Breadcrumb pages={[{ name: "Über uns", to: "/ueber-uns/" }]} />
			<Container noPadding className="py-6">
				<Grid cols={2} className="mb-16" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<H1 display className="mb-5 font-display">
							<span className="block mb-5 text-base">Lautenschlager Marketing & Entwicklung</span> Über uns
						</H1>
						<P className="mb-3">
							Wir sind Software-Entwickler und spezialisiert auf das Web und das dazugehörige Online-Marketing. Wir
							lieben unseren Job, weil der online Bereich ständig im Wandel ist und unbegrenzte Möglichkeiten bietet.
						</P>
						<P className="mb-3">
							In zahlreichen Gesprächen mit den Kunden und Interessenten ist uns besonders die Anzahl von Kunden und
							Unternehmen aufgefallen, welche sich zuerst für einen falschen Dienstleister entschieden haben. Das heißt
							sie haben wochen- oder sogar monatelang mit einer Agentur oder einem Freelancer gearbeitet, welche ein
							sehr geringes Know-how hatten und dadurch das Projekt nicht professionell umsetzen konnten. Also eine
							reine Zeit- und Geldverschwendung für den Kunden.
						</P>
						<P className="mb-3">
							Webseiten haben immer noch den Ruf, dass sie von Laien aufsetzen und erfolgreich betreiben kann. Die
							Wahrheit ist aber, dass eine Expertise in einer Programmiersprache sowie in Informatik und Softwaretechnik
							verlangt, damit die Projekte performant, sicher und stabil umgesetzt werden können. Darüber hinaus ist es
							notwendig sich mit Themen wie Server-Verwaltung, Hosting, DNS-Management, SSL-Zertifikaten, Datenbanken,
							Versionsverwaltung und der DSGVO auseinander zu setzen. Diese Expertise besitzen die meisten Unternehmer
							nicht. Vielleicht sind sie in der Lage einen Webbaukasten aufzusetzen und mit Inhalten zu pflegen, aber
							sie sind nicht in der Lage technische Probleme zu beheben und Marketingmaßnahmen zu planen. Dieses Problem
							beheben wir unter anderem mit unserem{" "}
							<Link to="/leistungen/website-abo/" className="underline">
								Website-Abo
							</Link>
							. So muss sich der Kunde keine Sorgen mehr um die Betreuung der Website machen und kann sich auf sein
							eigenes Business konzentrieren. Die Projekte, die bei uns landen, sind selten einfache Webseiten die
							out-of-the-box funktionieren. Die Webseite ist ein Marketinginstrument, welches einen Return on Invest
							bringen soll. Deshalb ist es wichtig über den Tellerrand zu schauen.
						</P>
						<P className="mb-8">
							Wenn Du also ein neues Projekt umsetzen möchtest oder ein Problem mit Deinem aktuellen Projekt hast, setz
							Dich direkt mit uns in Verbindung und vermeide teure Fehler. Du hast nichts zu verlieren.
						</P>
					</div>
					<figure>
						<StaticImage
							src="../img/pages/index/lautenschlager-marketing-entwicklung_header.png"
							alt="Andreas Lautenschlager"
							layout="fullWidth"
						/>
						<figcaption className="text-center">
							<span className="font-bold">Andreas Lautenschlager</span>
							<br />
							Entwicklung, Consulting und Organisation
						</figcaption>
					</figure>
				</Grid>
				<H2 display className="mb-5 font-display">
					Philosophie
				</H2>
				<Grid cols={2} className="mb-16">
					<div>
						<P>
							Der Biber ist mein Wahrzeichen und hat gleich mehrere Bedeutungen. Der kleine Baumeister steht für Arbeit,
							Fleiß, Kreativität, Geschick, Ausdauer und vieles mehr. Er symbolisiert die tägliche Arbeit jeden Tag an
							seinen Projekten zu arbeiten um am Ende ein immer größeres Konstrukt zu erstellen. Das ist die perfekte
							Analogie, wenn es darum geht digitale Projekte zu entwickeln, da diese ein ständiger Prozess sind und die
							oben genannten Eigenschaften verlangen. Diese Analogie ist auch das Grundkonzept unseres{" "}
							<Link to="/leistungen/website-abo/" className="underline">
								Website-Abos
							</Link>
							.
						</P>
					</div>
					<div>
						<LogoIcon className="w-48 h-48 mx-auto" />
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/ueber-uns/ueber-uns_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
